<template>
<b-card no-body>
    <b-card-body>
        <ValidationObserver ref="observer">
            <EKInputTextarea
                label="نص الرسالة"
                v-model="msg.value"
                :rules="[{type: 'required', message: 'الحقل مطلوب'}]"
                name="msg"
            />
        </ValidationObserver>
    </b-card-body>
    <b-card-footer>
        <b-button variant="primary" @click="send()">إرسال</b-button>
    </b-card-footer>
</b-card>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import EKInputTextarea from "@/EK-core/components/EK-forms/EK-input-textarea";
import { mapActions, mapState } from 'vuex'
export default {
    components: {
        EKInputTextarea,
        ValidationObserver
    },
    computed: {
        ...mapState({
            msg: state => state.store.msg
        })
    },
    methods: {
        ...mapActions(['sendMessage']),
        send() {
            this.$refs.observer.validate().then(success => {
                if (success) {
                    this.sendMessage(this.msg.value)
                    this.$refs.observer.reset()
                }
            })
        }
    }
}
</script>